<template>
  <div>
    <v-card>
      <v-card-title>
        <span>{{$t('t.License')}}</span>
        <v-btn
          class="ml-4"
          fab
          x-small
          @click.stop="showKeyPopup = true"
        >
          <v-icon ref="check">{{$icon('i.LiveEdition')}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-skeleton-loader
        type="image"
        class="risk"
        v-if="!license"
      />
      <div v-else>
        <v-card-subtitle>
          <span>{{$t('t.LicenseExpireInDays', { days: license.daysBeforeExpiration })}}</span>
        </v-card-subtitle>
        <v-card-text>
          <grid item-min-width="350px">
            <v-text-field
              :label="$t('t.EcosystemId')"
              v-model="license.ecosystemId"
              readonly
            />
            <v-text-field
              :label="$t('t.LicenseNbUser')"
              v-model="license.numberOfUser"
              readonly
            />
            <date-picker-menu
              v-model="license.expirationDate"
              :label="$t('t.LicenseExpirationDate')"
              readonly
            />
          </grid>
          <grid item-min-width="350px">
            <v-switch
              dense
              readonly
              :label="$t('t.LicenseDisputeResolversEnabled')"
              v-model="license.disputeResolversEnabled"
            />
            <v-switch
              dense
              readonly
              :label="$t('t.LicenseRiskModuleEnabled')"
              v-model="license.riskModuleEnabled"
            />
            <v-radio-group
              v-model="license.portalMode"
              readonly
            >
              <template v-slot:label>
                <span>{{$t('t.LicensePortalMode')}}</span>
              </template>
              <v-radio
                :label="$t('t.Full')"
                :value="1"
              ></v-radio>
              <v-radio
                :label="$t('t.InvoicesOnly')"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </grid>
        </v-card-text>
      </div>
    </v-card>
    <v-dialog
      v-model="showKeyPopup"
      max-width=650
      scrollable
    >
      <v-card>
        <v-card-title>{{$t('t.UpdateLicense')}}</v-card-title>
        <v-card-text>
          <v-textarea
            :label="$t('t.NewKey')"
            v-model="keyValue"
            clearable
            full-width
            rows="5"
            dense
            autofocus
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!keyValue"
            @click="applyKey()"
          >{{$t('t.Ok')}}</v-btn>
          <v-btn @click="showKeyPopup = false">{{$t('t.Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  mounted () {
    this.load()
  },
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    Grid: () => import('@/components/grid')
  },
  computed: {
  },
  data () {
    return {
      license: null,
      keyValue: null,
      showKeyPopup: false
    }
  },
  methods: {
    async applyKey () {
      await this.$http().post('/core/v6/settings/license', { keyToValidate: this.keyValue })
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    },
    async load () {
      this.showKeyPopup = false
      this.keyValue = null
      const r = await this.$http().get('/core/v6/settings/license')
      this.license = r?.data
    }
  }
}
</script>

<style lang="stylus" scoped></style>
